import React from "react";
import { colors, viewports } from "../../style-vars";

const ArticlesStyles = () => (
  <style jsx global>{`
    .articles-v2__bg-text {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 1%;
      font-weight: bold;
      font-size: 100px;
      line-height: 57.91%;
      color: #f3f4f6;
      letter-spacing: -0.02em;
      z-index: 0;
    }

    .articles-v2-card__head {
      position: relative;
      border-radius: 16px;
      margin-bottom: 24px;
    }
    .articles-v2-card__head img {
      width: 100%;
      border-radius: 16px;
      object-fit: cover;
    }
    .articles-v2-card__chip {
      color: ${colors.white};
      background-color: ${colors.primary};
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
      padding: 12px;
      position: absolute;
      right: 0;
      bottom: 32px;
      font-weight: 800;
    }

    .articles-v2-card__date {
      font-size: 16px;
      color: ${colors.paragraph};
      opacity: 0.8;
    }

    .articles-v2-card p {
      color: ${colors.paragraph};
      font-size: 16px;
      opacity: 0.8;
    }

    .articles-v2-card > h3 {
      color: #090d12 !important;
      font-size: 18px !important;
      margin-top: 16px;
      margin-bottom: 8px;
      line-height: 1.5 !important;
    }

    .articles-v2-card > :global(.cta-secondary) {
      display: flex;
    }

    @media ${viewports.lgOrBigger} {
      .articles-v2__bg-text {
        top: 9%;
        left: 0;
        font-weight: 800;
        font-size: 260px;
        line-height: 57.91%;
      }
    }

    @media ${viewports.xlOrBigger} {
      .articles-v2__bg-text {
        top: 1%;
        left: 0;
        font-size: 360px;
      }
    }
  `}</style>
);

export default ArticlesStyles;

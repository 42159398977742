import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { Row } from "antd";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import { isMobileOnly } from "react-device-detect";
import { ghostApi } from "../../api/ghostApi";
import ArticlesStyles from "./styles";
import Heading from "../Base/HeadingBuilder";
import useStartMotion from "../../hooks/use-start-motion";

const variants = {
  hidden: { x: "200vw" },
  visible: {
    x: 0,
    transition: {
      duration: 2,
    },
  },
};

const Articles = (props) => {
  const {
    sectionData,
    blogFilters,
    style,
    animations,
    hideSnippet,
    hideLink,
    offsets,
    dateFormat,
    imageHeight,
  } = props;
  const { title, bgText } = sectionData;
  const [blog, setBlog] = useState([]);
  const { ref, controls } = useStartMotion();

  useEffect(() => {
    ghostApi.posts
      .browse(blogFilters)
      .then((posts) => {
        setBlog(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <section
      className={`
        articles-v2
        relative max-w-1440
        py-24 px-4 mt-20
        lg:mt-36 lg:pt-40 lg:pb-20 lg:px-12 lg:my-12 lg:mx-auto
        xl:py-40 xl:mt-36 xl:mb-20 xl:my-16
      `}
      style={style}
      ref={ref}
    >
      {sectionData?.title && (
        <>
          <Heading
            level={2}
            injectionType={1}
            className="relative sm:text-center md:text-center lg:text-left z-1 xl:max-w-60-percent"
            withBlueSymbol
          >
            {title}
          </Heading>
        </>
      )}
      <Row gutter={[32, 64]}>
        {blog.map((entry, idx) => {
          const ArticleCard = loadable(() => import("./article-card"));
          return (
            <ArticleCard
              title={entry.title}
              headerImg={entry.feature_image}
              sizing={entry.sizing}
              category={entry?.primary_tag?.name ?? ""}
              snippet={entry.custom_excerpt}
              publishedDate={dayjs(entry.published_at).format(dateFormat)}
              linkTo={entry.url}
              key={`blog-entry__${idx.toString()}`}
              hideSnippet={hideSnippet}
              hideLink={hideLink}
              index={idx}
              needOffset={offsets}
              imageHeight={imageHeight}
            />
          );
        })}
      </Row>

      <motion.div
        className="articles-v2__bg-text"
        animate={controls}
        variants={variants}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
      >
        {bgText}
      </motion.div>

      <ArticlesStyles />
    </section>
  );
};

Articles.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  blogFilters: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.object),
  hideSnippet: PropTypes.bool,
  hideLink: PropTypes.bool,
  animations: PropTypes.bool,
  offsets: PropTypes.arrayOf(PropTypes.any),
  dateFormat: PropTypes.string,
  imageHeight: PropTypes.string,
};
Articles.defaultProps = {
  sectionData: {},
  blogFilters: {},
  hideSnippet: false,
  hideLink: false,
  style: {},
  animations: true,
  offsets: [],
  dateFormat: "MMMM-DD-YYYY",
  imageHeight: "",
};

export default Articles;
